<template>
  <div class="home">
    <div class="homeCenter">
      <div class="video-container">
        <el-carousel trigger="click" height="800px">
          <el-carousel-item v-for="(item,index) in carouselList" :key="index">
            <div class="carousel_img">
              <img :src="item.img" style="width:100%" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="between-logo-container">
        <img src="@/assets/between-logo.png" />
      </div>
      <div class="face-shield-container-first">
        <ul class="grid-first">
          <li class="grid-first-item" v-for="(item, index) in gridFirst" :key="index">
            <div class="item-container" @click="toDetails">
              <span>{{ item.name }}</span>
              <div class="img_box">
                <img :src="item.img" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="face-shield-container-second">
        <ul class="grid-second">
          <li class="grid-second-item" v-for="(item, index) in gridSecond" :key="index"
            @click="toDetailsSmall(item)">
            <div class="item-container">
              <img :src="item.img" />
            </div>
          </li>
        </ul>
      </div>
      <div class="face-shield-container-three">
        <div class="title">
          <span @click="$router.push({ name: 'drive' })">ドライビングレッスン</span>
        </div>
        <ul class="grid-three">
          <li class="grid-three-item" v-for="(item, index) in gridThree" :key="index">
            <div class="item-container">
              <img :src="item.img" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      carouselList: [
        {
          img: require('../../assets/home1.jpg'),
        },
        {
          img: require('../../assets/home2.jpg'),
        },
        {
          img: require('../../assets/home3.jpg'),
        },
        {
          img: require('../../assets/home4.png'),
        },
      ],
      gridFirst: [
        {
          img: require('../../assets/shield/111.jpg'),
          name: '二輪用品'
        },
        {
          img: require('../../assets/shield/222.jpg'),
          name: '四輪用品'
        },
        {
          img: require('../../assets/shield/444.jpg'),
          name: 'ドライビングレッスンスクール体験'
        },
      ],
      gridSecond: [
        {
          href: 'http://dinmarket.jp/brand/60s-plain',
          img: require('../../assets/GREASER.png'),
        },
        {
          href: 'http://dinmarket.jp/brand/shm-hand-stitch-lot-101-2',
          img: require('../../assets/SHM.png'),
        },
        {
          href: 'http://dinmarket.jp/brand/customgt-750',
          img: require('../../assets/GMG1.png'),
        },
        {
          href: 'https://www.72jam.com/',
          img: require('../../assets/72JAM.png'),
        },
        {
          href: 'http://dinmarket.jp/brand/gmg-07',
          img: require('../../assets/GMG.png'),
        },
        {
          img: require("../../assets/shm-helmet.png"),
          href:
            "http://dinmarket.jp/brand/%E3%82%AC%E3%83%B3%E3%82%B7%E3%83%83%E3%83%97%E3%82%B0%E3%83%AC%E3%83%BCx%E3%83%9C%E3%83%B3%E3%83%8D%E3%83%93%E3%83%AB%E3%82%BD%E3%83%AB%E3%83%88%E3%83%87%E3%83%8B%E3%83%A0/",
        }
      ],
      gridThree: [
        {
          img: require('../../assets/home-drive1.jpg')
        },
        {
          img: require('../../assets/home-drive2.jpg')
        }
      ]
    }
  },
  methods: {
    toDetailsSmall(item) {
      window.open(item.href)
    },
    toDetails() {
      this.$router.push({ name: 'brand' })
    }
  }
}
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  // height: 1200px;
  .homeCenter {
    width: 1300px;
    margin: 0 auto;


    .between-logo-container {
      width: 100%;
      padding: 10px 0;

      img {
        display: block;
        width: 100%;
        box-shadow: 2px 0 6px 8px rgb(0, 0, 0),
          6px 4px 6px 8px rgb(255, 255, 255, 0.7);
      }
    }

    .face-shield-container-first {
      width: 100%;

      .title {
        font-size: 30px;
        font-weight: 650;
        padding: 40px 0 50px;
        letter-spacing: 2px;
      }

      .grid-first {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .grid-first-item {
          margin-top: 60px;
          width: 16%;

          .item-container {
            width: 100%;
            font-size: 24px;
            color: #fff;
            align-items: center;
            justify-content: center;

            .img_box {
              width: 85%;
              height: 130px;
              margin: 0 auto;
              display: flex;
              align-items: center;
              box-shadow: 2px 0 6px 8px rgb(0, 0, 0),
                6px 4px 6px 8px rgb(255, 255, 255, 0.7);
            }

            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }

            span {
              display: block;
              transform: scale(0.8);
              margin: 30px auto 20px;
              height: 45px;
            }
          }
        }
      }
    }

    .face-shield-container-second {
      width: 100%;

      .grid-second {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
        flex-wrap: wrap;

        .grid-second-item {
          width: 30%;

          .title {
            width: 100%;
            font-size: 30px;
            font-weight: 650;
            transform: scale(0.9);
            font-weight: 650;
            margin: 20px auto;
            white-space: nowrap;
            overflow: hidden;
            letter-spacing: 2px;
          }

          .item-container {
            width: 100%;
            height: 280px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              cursor: pointer;
              width: 100%;
              display: block;
              margin: 0 auto;
              // box-shadow: 2px 0 6px 8px rgb(0, 0, 0),
              //   6px 4px 6px 8px rgb(255, 255, 255, 0.7);
            }

            span {
              display: block;
              transform: scale(0.8);
              margin: 30px auto 20px;
            }
          }
        }
      }
    }

    .face-shield-container-three {
      width: 100%;
      margin-bottom: 50px;

      .title {
        font-size: 30px;
        font-weight: 650;
        padding: 40px 0 50px;
        letter-spacing: 2px;

        span {
          cursor: pointer;
        }
      }

      .grid-three {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .grid-three-item {
          width: 48%;

          .item-container {
            width: 100%;
            font-size: 24px;
            color: #fff;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              display: block;
              margin: 0 auto;
              box-shadow: 2px 0 6px 8px rgb(0, 0, 0),
                6px 4px 6px 8px rgb(255, 255, 255, 0.7);
            }
          }
        }
      }
    }
  }
}</style>

<style>
.el-carousel__container {
  margin: 10px 0 ;
}
 .el-carousel__arrow {
  height: 60px !important;
  width: 60px !important;
  font-size: 40px !important;
}
.el-carousel__button{
  width: 80px;
  height: 3px;
}
.carousel_img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
